<template>
    <div class="content-wrapper">
        <bo-page-title />
        <div class="content">
            <div class="card">
            <div class="card-header bg-white">
                <div class="row align-items-center">
                <div class="col-md-5">
                    <div class="form-row align-items-center">
                    <div class="col-md-4">
                        <h5 class="card-title font-weight-semibold">Billing List</h5>
                    </div>
                    <div class="col-md-8">
                        <div class="form-group mb-0">
                                <div class="input-group">
                                    <date-range-picker
                                        ref="picker"
                                        :locale-data="datePickerConfig.locale"
                                        :autoApply="datePickerConfig.autoApply"
                                        v-model="dateRange"
                                        :opens="'right'"
                                        :ranges="datePickerConfig.ranges"
                                        @update="updateValues"
                                    >
                                        <template v-slot:input="picker">
                                            {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                        </template>
                                    </date-range-picker>
                                    <div class="input-group-append calendar-group">
                                        <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                    </div>
                                </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="card-header">
                <div class="row">
                <div class="col-md-7 col-lg-8">
                    <div class="row gx-1">
                    <div class="col-md-4">
                        <v-select placeholder="Pilih Status" @input="doFill" v-model="filter.status_pasien"  :options="Config.mr.statusPasien" label="text" :reduce="v=>v.value"></v-select>
                    </div>
                    <div class="col-md-4">
                        <v-select placeholder="Pilih Cara Bayar" @input="doFill" v-model="filter.status_cara_bayar"  :options="Config.mr.statusJaminan" label="text" :reduce="v=>v.value"></v-select>
                    </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                        <input class="form-control"
                            v-model="filter.search"
                            @input="doSearch"
                            placeholder="Ketik Nama/No. RM Pasien"
                        />
                        <div class="form-control-feedback">
                            <i class="icon-search4 text-indigo"></i>
                        </div>
                            
                        <b-button
                            class="ml-1 d-inline-flex align-items-center"
                            variant="outline-success"
                            id="resetBtn"
                            @click="doResetData()"
                        >Reset</b-button>
                    </div>
                </div>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
                <thead>
                    <tr>
                    <th>No. Reg</th>
                    <th>Nama</th>
                    <th>No RM</th>
                    <th>Cara Bayar</th>
                    <th>Status</th>
                    <th width="120">Aksi</th>
                    </tr>
                </thead>
                <tbody v-if="(dataList||[]).length">
                    <tr v-for="(v,k) in (dataList||[])" :key="k">
                        <td>
                            <div class="text-success-700 font-weight-semibold">{{v.ar_reg_code||"-"}}</div>
                            <small class=" font-weight-semibold" style="font-size: 18px;">{{v.ar_no_antrian||"-"}}</small>
                        </td>
                        <td>
                            <div>{{v.ap_fullname||"-"}}</div>
                        <td>
                            <span v-if="v.ap_is_old_pasien !== 'Y'" class="text-primary">PASIEN BARU</span>
                            <span v-else class="text-danger">PASIEN LAMA</span>     
                            <div>{{v.ap_code||"-"}}</div>
                        </td>

                        <td>
                            <div class="font-weight-semibold">{{getConfigDynamic(Config.mr.statusJaminan,v.arres_jaminan)||"-"}}</div>
                        </td>
                        <td>
                            <span class="badge badge-warning mb-1" v-if="v.ar_status == 1 && v.ar_process_status == 'QUEUE'">Menunggu </span>
                            <span class="badge badge-info mb-1" v-else> Dalam Tindakan </span>
                            
                            <span class="badge bg-primary mb-1" v-if="v.ar_is_fast_track == 'Y'">FAST TRACK</span>
                            <span class="badge bg-warning mb-1" v-if="v.arres_is_draft == 'Y'">Draft</span>
                            <span class="badge bg-warning" v-if="v.ar_is_verif_admisi !== 'Y'">Perlu Verifikasi</span>
                        </td>
                        <td>
                        <a href="javascript:;" @click="openDetail(v)" data-toggle="modal" data-target="#billing-detail"  class="btn btn-icon rounded-round btn-sm alpha-info border-info" data-original-title="Lihat Detail" aria-describedby="tooltip475130"><i class="icon-eye"></i></a>
                        </td>
                    </tr>
                </tbody>
                <tbody v-if="data.data.length == 0">
                    <tr>
                        <td colspan="99">
                        <div class="text-center">
                            <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                            <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                        </div>
                        </td>
                    </tr>
                </tbody>
                
                <tbody v-if="!data.data">   
                    <tr>
                        <td colspan="99"><div class="skeletal-comp"></div></td>
                    </tr>
                    <tr>
                        <td colspan="99"><div class="skeletal-comp"></div></td>
                    </tr>
                    <tr>
                        <td colspan="99"><div class="skeletal-comp"></div></td>
                    </tr>
                </tbody>
                </table>
            </div>            
            <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">  <b-pagination
                class="mb-0"
                v-model="pageNo"
                :per-page="data.per_page"
                :total-rows="data.total"
            />
            </b-card-footer>
            </div>
            <!-- /dashboard content -->
        </div>
        
        <b-modal v-model="detailData" :title="'Detail Billing'" size="lg" ok-only ok-title="Tutup">
            <div class="row" id="billing">
                <div class="col-md-5">
                <table class="table table-borderless table-sm text-uppercase">
                    <tbody>
                    <tr>
                        <td>Tanggal </td>
                        <td>:</td>
                        <td>{{row.ar_reg_date | moment("DD MMMM YYYY, HH:mm")}}</td>
                    </tr>
                    <tr>
                        <td>Nama Pasien </td>
                        <td> :</td>
                        <td>{{row.ap_fullname||"-"}}</td>
                    </tr>
                    <tr>
                        <td>Nomor RM</td>
                        <td> :</td>
                        <td>{{row.ap_code||"-"}}</td>
                    </tr>
                    <tr>
                        <td>Nomor Reg</td>
                        <td>: </td>
                        <td>{{row.ar_reg_code}}</td>
                    </tr>
                    </tbody>
                </table>
                </div>
                <div class="col-md-1">
                </div>
                <div class="col-md-6">
                <table class="table table-borderless table-sm text-uppercase">
                    <tbody>
                    <tr>
                        <td>Alamat </td>
                        <td>:</td>
                        <td>Jl. Cemara II, No.15C Pamulang Barat</td>
                    </tr>
                    <tr>
                        <td>Jenis Kelamin </td>
                        <td> :</td>
                        <td>{{row.ap_gender == 1 ? "Laki-laki" : "Perempuan"}}</td>
                    </tr>
                    <tr>
                        <td>Usia</td>
                        <td> :</td>
                        <td>{{row.ap_usia_with_ket||"-"}}</td>
                    </tr>
                    <tr>
                        <td>Dokter/POLI</td>
                        <td> :</td>
                        <td>{{row.bu_full_name||"-"}}</td>
                    </tr>
                    </tbody>
                </table>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mt-3">
                <div class="table-responsive">
                    <table class="table table-bordered  table-sm patient-table text-uppercase table-sm">
                    <thead>
                        <tr>
                        <th>Deskripsi</th>
                        <th></th>
                        <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td colspan="3">
                            <div class=" font-weight-semibold">Tindakan dan Layanan Medis</div>
                        </td>
                        </tr>
                        <tr>
                        <td style="padding-left:30px;">
                            Konsultasi Dokter<br>
                            DR. BUDI WIRANTO, SP. THT/POLI THT
                        </td>
                        <td>
                            <div>Rp 0</div>
                        </td>
                        <td>
                            <div>Rp 0</div>
                        </td>
                        </tr>
                        <tr>
                        <td style="padding-left:30px;">
                            Laboratorium
                        </td>
                        <td>
                            <div>Rp 0</div>
                        </td>
                        <td>
                            <div>Rp 0</div>
                        </td>
                        </tr>
                        <tr>
                        <td style="padding-left:30px;">
                            Radiologi
                        </td>
                        <td>
                            <div>Rp 0</div>
                        </td>
                        <td>
                            <div>Rp 0</div>
                        </td>
                        </tr>
                        <tr>
                        <td style="padding-left:30px;">
                            Obat-obatan
                        </td>
                        <td>
                            <div>Rp 0</div>
                        </td>
                        <td>
                            <div>Rp 0</div>
                        </td>
                        </tr>
                    </tbody>
                    <tfoot style="border-top: 3px solid #162c5e; border-bottom: 2px solid #162c5e;">
                        <tr>
                        <th></th>
                        <th></th>
                        <th class="table-info">
                            <div class="font-weight-semibold">Rp 0</div>
                        </th>
                        </tr>
                    </tfoot>
                    </table>
                </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>


<script>
    import GlobalVue from '@/libs/Global.vue'
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    const moment = require('moment')
    import Gen from '@/libs/Gen.js'

    const _ = global._
    import $ from 'jquery'
    export default {
        extends: GlobalVue,
        data(){
            return {
                idKey:'arres_id',
                datePickerConfig: {
                    startDate: new Date(),
                    endDate: new Date(),
                    autoApply: true,
                    ranges: {
                        'Hari Ini': [new Date(), new Date()],
                        '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                        '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                    },
                    applyClass: 'btn-sm btn-primary',
                    cancelClass: 'btn-sm btn-light',
                    locale: {
                        applyLabel: 'Terapkan',
                        cancelLabel: 'Batal',
                        direction: 'ltr',
                        format: 'mm/dd/yyyy',
                        separator: ' - ',
                    }
                },
                dateRange: {
                    startDate: new Date(),
                    endDate: new Date(),
                },
                rowEdit : {},
                detailData: false
            }
        },
  	    components:{DateRangePicker},
        methods: {        
            doConvertDate(){
                this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
                this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
            },
            doPageOne(){
                this.filter.page = 1
            },
            doFill(){
                this.doPageOne()
                this.doFilter()
            },
            updateValues(e){
                this.doConvertDate()
                this.doFill()
            },
            doSearch: _.debounce(function(){
                this.doPageOne()
                this.doFilter()
            },100),
            getConfigDynamic(master,value){
                let text = ''
                if(value){
                    let index = master.findIndex(x => x.value == value)
                    if(index !== -1){
                        text = master[index]['text']
                    }
                }
                return text
            },
            openDetail(v){
                this.loadingOverlay = true                

                Gen.apiRest(
                    "/get/"+'RoReservasi'+'/'+v.arres_id, 
                ).then(res=>{
                    this.loadingOverlay = false
                    this.detailData = true
                    this.row = res.data.row
                    console.log(this.row)
                    
                })
                
            }
        },
        mounted(){
            this.doConvertDate()      
            this.apiGet()
        },
        filters: {
            date(val) {
                return val ? moment(val).format("D MMM YYYY") : ""
            }
        },
        watch:{
            $route(){
                this.apiGet()
            },
            '$route.params.pageSlug':function(){
                this.$set(this,'filter',{})
            }
        }
    }
</script>